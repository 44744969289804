<template>
  <!-- Under maintenance-->
  <div class="">
    

    <div class=" p-2 p-sm-3">
      <h3 class="text-danger">Under Maintenance, Please Check Once Communicated by HR</h3>
      <h4>List of Documents </h4>
        <ol>
          <li>Leave Policy</li>
          <li>Work Policy</li>
          <li>Incentive Policy</li>
          <li>Stack Ranking Policy</li>
        </ol>
    </div>
  </div>
<!-- / Under maintenance-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BFormInput, BButton, BForm, BImg,
} from 'bootstrap-vue'
import SattvaLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'

export default {
  components: {
    BLink,
    BFormInput,
    BButton,
    BForm,
    BImg,
    SattvaLogo,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/under-maintenance.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/under-maintenance-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    // openPolicyDoc() {
    //   // window.open("https://ats.sattvahuman.in/assets/documents/Leave policy 2024.pdf")
    //   window.open("http://localhost:8080/assets/documents/Leave policy 2024.pdf")
    // }
    
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
